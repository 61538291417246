import { Injectable } from '@angular/core';
import { isDefined } from '@interacta-shared/util';
import { selecHomeWithUsefulLinks } from '@modules/communities/store/home-pages/home-pages.selectors';
import { i18nStringV2 } from '@modules/core';
import { WidgetType } from '@modules/digital-workplace/models/digital-workplace.model';
import { isUsefulLinkWidget } from '@modules/digital-workplace/models/digital-workplace.utils';
import { UsefulLinksGroup } from '@modules/useful-link/models/useful-link.model';
import { Store } from '@ngrx/store';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { LinksDialogState } from '../models/links-dialog-state.model';
import { IStateService } from './istate-service';
import { StateService } from './state.service';

@Injectable({ providedIn: 'root' })
export class LinkDialogStateService implements IStateService<LinksDialogState> {
    readonly state: LinksDialogState;

    flush$ = new Subject<void>();

    constructor(
        private stateService: StateService,
        private store: Store,
    ) {
        this.state = stateService.linksDialogState;
    }

    isOpen(): boolean {
        return this.state.isOpen.value;
    }

    initialize(): void {
        this.state.externalLinks.next(undefined);
        this.state.dwDefinitions.next(undefined);
        this.state.isOpen.next(false);
    }

    flush(): void {
        this.initialize();
        this.flush$.next();
    }

    getLinks(search?: string): void {
        combineLatest([
            this.stateService.externalLinksState.externalLinks$,
            this.store.select(selecHomeWithUsefulLinks),
        ])
            .pipe(takeUntil(this.flush$))
            .subscribe(([externalLinks, dwDefinitions]) => {
                const lowerCaseString = search?.toLowerCase();
                if (lowerCaseString) {
                    this.state.externalLinks.next(
                        externalLinks?.filter((el) =>
                            el.label.toLowerCase().includes(lowerCaseString),
                        ) ?? undefined,
                    );

                    const newDwDefinitions = dwDefinitions
                        ?.map((dwDef) => {
                            //home
                            const foundedHome = this.stringsMatch(
                                dwDef.name,
                                lowerCaseString,
                            );
                            if (foundedHome) {
                                return dwDef;
                            } else {
                                //widget
                                const widgetFounded = dwDef.widgets.filter(
                                    (w) =>
                                        w.tag === WidgetType.USEFUL_LINK &&
                                        this.stringsMatch(
                                            w.name,
                                            lowerCaseString,
                                        ),
                                );
                                if (widgetFounded.length) {
                                    return { ...dwDef, widgets: widgetFounded };
                                } else {
                                    //group or link
                                    const widgetsWithGroupsOrLinksFounded =
                                        dwDef.widgets
                                            .filter(isUsefulLinkWidget)
                                            .map((w) => ({
                                                ...w,
                                                linksGroups:
                                                    this.groupsOrLinksMatch(
                                                        w.linksGroups,
                                                        lowerCaseString,
                                                    ),
                                            }))
                                            .filter(
                                                (w) => w.linksGroups.length,
                                            );
                                    if (
                                        widgetsWithGroupsOrLinksFounded.length
                                    ) {
                                        return {
                                            ...dwDef,
                                            widgets:
                                                widgetsWithGroupsOrLinksFounded,
                                        };
                                    } else {
                                        return null;
                                    }
                                }
                            }
                        })
                        .filter(isDefined);
                    if (newDwDefinitions) {
                        this.state.dwDefinitions.next(newDwDefinitions);
                    }
                } else {
                    this.state.externalLinks.next(externalLinks ?? undefined);
                    this.state.dwDefinitions.next(dwDefinitions ?? undefined);
                    const counter =
                        (externalLinks?.length ?? 0) +
                        (dwDefinitions?.reduce(
                            (acc, curr) =>
                                acc +
                                curr.widgets.reduce((acc, curr) => {
                                    let count = 0;
                                    if (curr.tag === WidgetType.USEFUL_LINK) {
                                        count = curr.linksGroups.reduce(
                                            (acc, curr) =>
                                                acc + curr.links.length,
                                            0,
                                        );
                                    }
                                    return acc + count;
                                }, 0),
                            0,
                        ) ?? 0);
                    this.state.linksTotalCount.next(counter);
                }
            });
    }

    groupsOrLinksMatch(
        groups: UsefulLinksGroup[],
        search: string,
    ): UsefulLinksGroup[] {
        return groups.filter(
            (g) =>
                this.stringsMatch(g.name, search) ||
                g.links.some(
                    (l) =>
                        this.stringsMatch(l.name, search) ||
                        (l.url && this.stringsMatch(l.name, search)),
                ),
        );
    }

    stringsMatch(label: i18nStringV2, search: string): boolean {
        return (label.label ?? '').toLocaleLowerCase().includes(search);
    }

    open(): void {
        this.state.isOpen.next(true);
        this.getLinks();
    }

    close(): void {
        this.state.isOpen.next(false);
    }
}
