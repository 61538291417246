<div class="w-204 h-full">
    @if (links.length === 1) {
        @let link = links[0];
        <button
            class="bg-surface-A h-fit w-full rounded-2xl p-8 text-left shadow"
            type="button"
            [attr.aria-disabled]="isHomePreview"
            [disabled]="isHomePreview"
            (click)="navigateTo(link)"
        >
            <div
                class="bg-surface-A flex aspect-video justify-center overflow-hidden rounded-lg"
            >
                @if (
                    link.temporaryContentPreviewImageLink ??
                        link.temporaryContentViewLink;
                    as src
                ) {
                    <interacta-image
                        alt="{{ link.name }}'s image"
                        [classes]="'h-full w-auto'"
                        [src]="src"
                        (loadError)="imageLoadError.emit(src.split('?')[0])"
                    />
                } @else if (!link.communityId) {
                    <div
                        class="size-full"
                        [ngStyle]="{
                            background:
                                idx | apply: getRandomSecondaryColorVariables,
                        }"
                    ></div>
                } @else {
                    <interacta-image
                        [alt]="'Community cover fallback'"
                        [classes]="'h-full w-auto'"
                        [src]="'assets/images-2.0/fallback.jpg'"
                    />
                }
            </div>
            <div class="mt-8 flex h-24 items-center justify-between">
                @if (link.name | currentLanguageV2; as name) {
                    <p
                        class="typo-sm line-clamp-1 break-words"
                        [matTooltip]="name"
                    >
                        {{ name }}
                    </p>
                }
                @if (!link.communityId) {
                    <interacta-icon
                        class="size-24 shrink-0"
                        [icon]="'external-link'"
                    />
                }
            </div>
        </button>
    } @else {
        <div class="flex size-full flex-col gap-y-8">
            <p
                *ngIf="name"
                class="typo-sm-bold-uppercase line-clamp-1 break-words"
            >
                {{ name }}
            </p>
            @for (link of links; track link.uuid) {
                <button
                    class="bg-surface-A flex h-24 w-full flex-1 items-center justify-between rounded-lg px-8 py-4 text-left shadow"
                    type="button"
                    [attr.aria-disabled]="isHomePreview"
                    [disabled]="isHomePreview"
                    (click)="navigateTo(link)"
                >
                    @if (link.name | currentLanguageV2; as name) {
                        <p
                            class="typo-sm line-clamp-1 break-words"
                            [matTooltip]="name"
                        >
                            {{ name }}
                        </p>
                    }
                    @if (!link.communityId) {
                        <interacta-icon
                            class="size-24 shrink-0"
                            [icon]="'external-link'"
                        />
                    }
                </button>
            }
        </div>
    }
</div>
