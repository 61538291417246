import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { getRandomSecondaryColorVariables } from '@interacta-shared/util';
import { dashboardCommunity } from '@modules/app-routing/routes';
import { UsefulLink } from '@modules/useful-link/models/useful-link.model';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '@interacta-shared/data-access-auth';

@Component({
    selector: 'interacta-useful-link',
    templateUrl: './useful-link.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsefulLinkComponent {
    @Input({ required: true }) links!: UsefulLink[];
    @Input({ required: true }) idx!: number;
    @Input() name = '';
    @Input() isHomePreview = false;

    @Output() imageLoadError = new EventEmitter<string>();

    private PROTOCOL_VALIDATOR = new RegExp(/^[^:]+(?=:\/\/)/);

    private router = inject(Router);
    private authService = inject(AuthService);

    readonly getRandomSecondaryColorVariables =
        getRandomSecondaryColorVariables;

    async navigateTo(link: UsefulLink): Promise<void> {
        if (link.communityId) {
            this.router.navigate([dashboardCommunity, link.communityId]);
        } else if (link.url) {
            const _accessToken = link.url.includes('{accessToken}')
                ? await firstValueFrom(this.authService.renewAccessToken())
                : '';
            const url = link.url.replace('{accessToken}', _accessToken);

            window.open(
                this.PROTOCOL_VALIDATOR.exec(url) ? url : `https://${url}`,
                '_blank',
            );
        }
    }
}
